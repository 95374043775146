/*
 * @Author: ldy
 * @Date: 2022-02-17 17:11:04
 * @LastEditors: ldy 772995364@qq.com
 * @LastEditTime: 2023-06-15 10:12:59
 * @Description:
 */
import { Col } from 'antd'
import React from 'react'
import { getChildrenToRender, isImg } from './utils'

class Footer extends React.Component {
  static defaultProps = {
    className: 'footer1'
  }

  getLiChildren = data =>
    data.map((item, i) => {
      const { title, childWrapper, ...itemProps } = item
      return (
        <Col key={i.toString()} {...itemProps} title={null} content={null}>
          <h2 {...title}>
            {typeof title.children === 'string' && title.children.match(isImg) ? (
              <img src={title.children} width='100%' alt='img' />
            ) : (
              title.children
            )}
          </h2>
          <div {...childWrapper}>{childWrapper.children.map(getChildrenToRender)}</div>
        </Col>
      )
    })

  render() {
    const { ...props } = this.props
    const { dataSource } = props
    delete props.dataSource
    delete props.isMobile
    const childrenToRender = this.getLiChildren(dataSource.block.children)
    return (
      <div
        {...props}
        {...dataSource.wrapper}
        style={{ minHeight: 80, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        {/* <OverPack {...dataSource.OverPack}> */}
        {/* <QueueAnim
            type="bottom"
            key="ul"
            leaveReverse
            component={Row}
            {...dataSource.block}
          >
            {childrenToRender}
          </QueueAnim> */}
        {/* <TweenOne
            animation={{ y: '+=30', opacity: 0, type: 'from' }}
            key="copyright"
            {...dataSource.copyrightWrapper}
          >
            <div {...dataSource.copyrightPage}>
              <div {...dataSource.copyright}>
                {dataSource.copyright.children}
              </div>
            </div>
          </TweenOne> */}
        备案号：<a href='https://beian.miit.gov.cn/'>蜀ICP备19020877号-2</a>
        {/* </OverPack> */}
      </div>
    )
  }
}

export default Footer
